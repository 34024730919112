<template>
  <div>
    <slick :options="slickOptions" v-if="galleryArtistRecentArts.length > 5">
      <template v-for="(item, i) in galleryArtistRecentArts">
        <div class="item" :key="i">
          <div class="sm_card_list_item cursor-pointer" @click="onClickArtwork(item.idx)">
            <v-lazy :options="{ threshold: 0.5 }" transition="fade-transition">
              <div class="card_media_box" @contextmenu.prevent.stop>
                <b-img
                  :style="{display: isProductFileTypeImage(item.productFileType) ? 'block' : 'none'}"
                  :src="replaceDisplayCardUrl(item)">
                </b-img>
                <video
                  :style="{display: isProductFileTypeImage(item.productFileType) ? 'none' : 'block'}"
                  poster="/img/threespace/media/poster_default.png"
                  :src="replaceDisplayCardUrl(item)"
                  type="video/webm"
                  muted
                  loop
                  autoplay
                  playsinline
                ></video>
                <h3>
                  <template v-if="activeLanguage !== undefined && activeLanguage === 'ko'">
                    {{ item.productName || item.productNameEn }}
                  </template>
                  <template v-else>
                    {{ item.productNameEn || item.productName }}
                  </template>
                </h3>
              </div>
            </v-lazy>
            <div class="card-info">
              <div class="card_title_box" v-if="!isBusy">
                <div class="artist_name" @click.stop="onClickArtist(item.idxMember)">
                  <CImg
                    class="c-avatar-img mr-2"
                    height="25"
                    src="/img/avatars/profile-gray-person.jpg"
                    :style="{display: item.profileImage === undefined ? 'block' : 'none'}"
                  />
                  <CImg
                    class="c-avatar-img mr-2"
                    height="25"
                    :src="item.profileImageUrl"
                    :style="{display: item.profileImage === undefined ? 'none' : 'block'}"
                  />
                  <template v-if="activeLanguage !== undefined && activeLanguage === 'ko'">
                    {{ item.artistName || item.artistNameEn }}
                  </template>
                  <template v-else>
                    {{ item.artistNameEn || item.artistName }}
                  </template>
                </div>
                <CImg
                  src="/img/threespace/icon/ethereum-black.png"
                  v-if="item.nftType === 'ETHEREUM'"
                  class="chain-logo"
                />
                <CImg
                  src="/img/threespace/icon/matic-black.png"
                  v-if="item.nftType === 'POLYGON'"
                  class="chain-logo"
                />
                <CImg
                  src="/img/threespace/icon/kaia-black.png"
                  v-else-if="item.nftType === 'KLAYTN' || item.nftType === 'KLIP'"
                  class="chain-logo"
                />
                <CImg
                  src="/img/threespace/icon/bitcoin-black.png"
                  v-else-if="item.nftType === 'ORDINALS'"
                  class="chain-logo"
                />
              </div>
              <div class="card_title_box" v-else>
                <p class="artist_name">
                  <b-skeleton
                    type="avatar"
                    height="30px"
                    width="30px"
                    class="float-left"
                  ></b-skeleton>
                  <b-skeleton class="float-left mt-2 ml-2" width="100px"></b-skeleton>
                </p>
              </div>
              <div class="card_price_box" v-if="!isBusy">
                <div class="text_wrap" :class="{soldout: item.status === 'SOLDOUT' || item.status === 'PRIVATESOLD'}">
                  <template v-if="item.status === 'SOLDOUT' || item.status === 'PRIVATESOLD'">
                    {{
                      item.buyerNickname !== undefined && item.buyerNickname !== '' ?
                        "Owned by @" + item.buyerNickname :
                        $t("product.statusSoldOut")
                    }}
                  </template>
                  <template v-else-if="item.status === 'EXHIBIT'">
                    {{ $t("product.statusExhibit") }}
                  </template>
                  <template
                    v-else-if="
                        item.nftType === 'ORDINALS' &&
                        activeLanguage !== undefined &&
                        activeLanguage === 'en'
                      "
                  >
                    {{ getSatoshiToBtc(item.productCoinPrice)}} {{getCoinCurrencyByNftType(item.nftType)}}
                  </template>
                  <template
                    v-else-if="item.nftType === 'ORDINALS'"
                  >
                    {{ getSatoshiToKRW(item.productCoinPrice)}} KRW
                  </template>
                  <template
                    v-else-if="
                        activeLanguage !== undefined &&
                        activeLanguage === 'en' &&
                        isCoinPriceNonZero(item.nftType) &&
                        isAcceptableNftType(item.nftType)
                      "
                  >
                    {{ getProductCoinPrice(item.productPrice, item.nftType) }} {{getCoinCurrencyByNftType(item.nftType)}}
                  </template>
                  <template v-else>
                    {{ item.productPrice | currency }} {{ item.productPrice !== undefined && item.productPrice > 0 ? "KRW" : $t("product.statusExhibit") }}
                  </template>
                </div>
                <div class="icon_wrap">
                  <FavoritedButton
                    :favoritedItem="item"
                    @onClickFavoritedButton="onClickFavoritedButton"
                  />
                </div>
              </div>
              <div class="card_price_box" v-else>
                  <span>
                    <b-skeleton width="50%" height="25px"></b-skeleton>
                  </span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </slick>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import Slick from "vue-slick";
import "slick-carousel/slick/slick.css";
import coinUtils from "@/mixins/CoinUtils";
import FavoritedButton from "@/views/threespace/components/common/FavoritedButton";

export default {
  name: "ArtworkArtList",
  components: {
    Slick,
    FavoritedButton
  },
  data() {
    return {
      isBusy: false,
      slickOptions: {
        arrows: false,
        dots: false,
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        centerPadding: '20px',
        autoplay: false,
        responsive: [
          {
            breakpoint: 960,
            settings: {
              arrows: true,
              dots: false,
              infinite: true,
              slidesToShow: 2,
              slidesToScroll: 1,
              autoplay: false,
            }
          }
        ]
      },
    };
  },
  mixins: [
    coinUtils
  ],
  computed: {
    ...mapState("gallery", ["galleryArtistRecentArts"]),
    activeLanguage() {
      return this.getActiveLanguage();
    },
  },
  methods: {
    ...mapActions("gallery", ["getGalleryArtistRecentArts"]),
    ...mapActions("auth", ["verifyTokenCallback"]),
    requestArtistRecentArts(idxArtist) {
      this.isBusy = true;
      this.getGalleryArtistRecentArts(idxArtist)
        .then(() => {
          this.isBusy = false;
        })
        .catch(error => {
          this.isBusy = false;
          this.$log.error(error);
        });
    },
    isProductFileTypeImage(_productFileType) {
      return _productFileType !== undefined && _productFileType.startsWith("image")
    },
    onClickArtwork(idx) {
      this.$router.push("/art/" + idx);
    },
    getActiveLanguage() {
      return localStorage.getItem("language") || "en";
    },
    onClickFavoritedButton(favoritedItem) {
      if (this.isAuthenticated) {
        this.verifyTokenCallback()
          .then(() => {
            if (
              this.me === undefined ||
              this.me === null ||
              this.me.idx === undefined ||
              this.me.idx === null ||
              this.me.idx === ""
            ) {
              // 로그인
              this.hasKlaytnAddr = false;
              this.$store.commit("setCommonAlertModalInfo", {
                msgHtml: this.$t("validation.requiredLogin"),
                show: true,
                fontSize: "sm"
              })
            } else {
              // 로그인 되어있음
              if (favoritedItem.favorited) {
                // true이면 좋아요 헤재
                this.toggleFavorited(favoritedItem);
                this.deleteFavorited({idxCollection: favoritedItem.idx});
              } else {
                // false이면 좋아요
                this.toggleFavorited(favoritedItem);
                this.addFavorited({idxCollection: favoritedItem.idx});
              }
            }
          })
      } else {
        this.$store.commit("setCommonAlertModalInfo", {
          msgHtml: this.$t("validation.requiredLogin"),
          show: true,
          fontSize: "sm"
        })
      }
    },
    replaceDisplayCardUrl(item) {
      if (
          item.displayCardFile !== undefined &&
          item.displayCardFile !== null &&
          item.displayCardFile !== "" &&
          item.displayCardFile === "THREESPACE"
      ) {
        return item.displayCardUrlThreespace; // 3space S3 URL
      } else {
        if (item.displayCardUrl !== undefined && item.displayCardUrl.startsWith("https://ipfs.io/")) {
          return item.displayCardUrl.replace("https://ipfs.io/", "https://ipfs.3space.art/");
        }
        return item.displayCardUrl;
      }
    },
  }
};
</script>
