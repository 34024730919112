<template>
  <div>
    <div id="Artwork">
      <div class="artwork-section">
        <div class="artwork_background_wrap">
          <div
            class="artwork_wrap"
          >
            <div class="artwork_box">
              <template v-if="isProductFileTypeImage(galleryProductInfo.productFileType)">
                <b-img
                  :src="replaceDisplayDetailUrl(galleryProductInfo)"
                  @contextmenu.prevent.stop
                ></b-img>
              </template>
              <template v-else>
                <video
                  :poster="replaceDisplayPosterUrl(galleryProductInfo)"
                  :src="replaceDisplayDetailUrl(galleryProductInfo)"
                  type="video/webm"
                  loop
                  @contextmenu.prevent.stop
                  playsinline
                ></video>
              </template>
            </div>
          </div>
          <div
            id="productMediaWrap"
            ref="productMediaWrap"
            v-show="isProductItemLoaded"
          >
            <template v-if="isProductFileTypeImage(galleryProductInfo.productFileType)">
              <b-img
                id="artworkImage"
                ref="artworkImage"
                :src="replaceDisplayDetailUrl(galleryProductInfo)"
                @contextmenu.prevent.stop
              ></b-img>
            </template>
            <template v-else>
              <video
                id="artworkVideo"
                ref="artworkVideo"
                :poster="replaceDisplayPosterUrl(galleryProductInfo)"
                :src="replaceDisplayDetailUrl(galleryProductInfo)"
                type="video/webm"
                loop
                autoplay
                :muted="videoOptions.muted"
                @contextmenu.prevent.stop
                playsinline
              ></video>
            </template>
            <div class="artwork_controls_box">
              <template v-if="galleryProductInfo.productSound && !isMobile">
                <b-button
                  v-if="videoOptions.muted"
                  variant="secondary"
                  pill
                  size="lg"
                  @click="toggleClickMuted"
                >
                  <v-icon class="text-black">mdi-volume-off</v-icon>
                </b-button>
                <b-button
                  v-else
                  variant="secondary"
                  pill
                  size="lg"
                  @click="toggleClickMuted"
                >
                  <v-icon class="text-black">mdi-volume-high</v-icon>
                </b-button>
              </template>
              <template v-else>
                <b-button
                  v-if="!isMobile"
                  variant="secondary"
                  pill
                  size="lg"
                  disabled
                >
                  <v-icon class="text-gray">mdi-volume-high</v-icon>
                </b-button>
              </template>
              <b-button
                variant="secondary"
                pill
                size="lg"
                class="ml-2"
                v-show="!isMobile"
                @click="toggleFullScreen"
              >
                <v-icon class="text-black">mdi-fullscreen</v-icon>
              </b-button>
            </div>
          </div>
        </div>

        <div class="artwork_detail_box">
          <div class="artist-info">
            <div class="artist_profile_box cursor-pointer" @click="onClickArtist">
              <template v-if="galleryProductInfo.profileImage !== undefined">
                <b-img-lazy
                  v-if="isProductItemLoaded"
                  v-bind="imageProps"
                  :src="galleryProductInfo.profileImageUrl"
                  class="c-avatar-img"
                />
              </template>
              <template v-else>
                <b-img src="/img/avatars/profile-3space-logo.png" class="c-avatar-img" />
              </template>
            </div>
            <div class="artist_summary_box">
              <div class="name cursor-pointer" @click="onClickArtist">
                <template v-if="activeLanguage !== undefined && activeLanguage === 'ko'">
                  {{ galleryProductInfo.artistName || galleryProductInfo.artistNameEn }}
                </template>
                <template v-else>
                  {{ galleryProductInfo.artistNameEn || galleryProductInfo.artistName }}
                </template>
                <span class="nickname">@{{galleryProductInfo.artistNickName}}</span>
              </div>
              <div class="artwork-name">
                <template v-if="activeLanguage !== undefined && activeLanguage === 'ko'">
                  {{ galleryProductInfo.productName || galleryProductInfo.productNameEn }}
                </template>
                <template v-else>
                  {{ galleryProductInfo.productNameEn || galleryProductInfo.productName }}
                </template>
              </div>
            </div>
          </div>
          <div class="artwork-info">
            <div class="artwork_description_box">
              <div class="box-title">{{ $t("product.description") }}</div>
              <div class="description-wrap">
                <div
                  class="description"
                  :class="{ more: showMore && isOverText }"
                  ref="productIntroduceText"
                  v-html="getReplaceNewLineDesc()"
                  :style="{wordBreak:this.galleryProductInfo.nftType === 'ORDINALS' && 'break-word'}"
                />
                <a
                  class="more-btn cursor-pointer"
                  v-if="isOverText"
                  @click="showMore = !showMore"
                >
                  <u>{{ showMore ? $t("product.more") : $t("product.close") }}</u>
                </a>
              </div>
            </div>
            <hr v-if="!(galleryProductInfo.nftType === 'ORDINALS')"/>
            <div class="metadata-wrap" v-if="!(galleryProductInfo.nftType === 'ORDINALS')">
              <div class="metadata-item">
                <div class="type">
                  DIMENSION
                </div>
                <div class="value">
                  {{ galleryProductInfo.productSizeX }} X {{ galleryProductInfo.productSizeY }}
                </div>
              </div>
              <div class="metadata-item">
                <div class="type">
                  YEAR CREATED
                </div>
                <div class="value">
                  {{ galleryProductInfo.paintedAt }}
                </div>
              </div>
              <div class="metadata-item">
                <div class="type">
                  EDITION
                </div>
                <div class="value">
                  {{ galleryProductInfo.productEdition }}
                </div>
              </div>
            </div>
            <hr/>
            <div class="exhibit-wrap" v-if="galleryProductInfo.status === 'EXHIBIT' && galleryProductInfo.nftType !== 'ORDINALS'">
              {{ $t("product.proofMessage") }}
            </div>
            <div class="price-wrap" v-else>
              <div class="status-wrap">
                <div class="status">
                  <template v-if="galleryProductInfo.status === 'SOLDOUT' || galleryProductInfo.status === 'PRIVATESOLD'">{{ $t("product.statusSoldOut") }}</template>
                  <template v-else-if="galleryProductInfo.status === 'PREPARING'">{{ $t("product.statusPreparing") }}</template>
                  <template v-else>{{ $t("product.statusForSaleCard") }}</template>
                </div>
              </div>
              <div class="price">
                <template v-if="galleryProductInfo.status === 'PREPARING' || galleryProductInfo.status === 'FORSALE' || galleryProductInfo.status === 'RESERVE' || galleryProductInfo.status === 'SOLDOUT' || galleryProductInfo.status === 'PRIVATESOLD'">
                  <template v-if="galleryProductInfo.status !== 'SOLDOUT' &&
                    galleryProductInfo.status !== 'PRIVATESOLD' &&
                    activeLanguage !== undefined && activeLanguage === 'en' &&
                    isCoinPriceNonZero(galleryProductInfo.nftType) &&
                    isAcceptableNftType(galleryProductInfo.nftType)">
                    <template v-if="galleryProductInfo.nftType === 'ORDINALS'">
                      {{ getSatoshiToBtc(galleryProductInfo.productCoinPrice)}} {{getCoinCurrencyByNftType(galleryProductInfo.nftType)}}
                    </template>
                    <template v-else>
                      {{ getProductCoinPrice(galleryProductInfo.productPrice, galleryProductInfo.nftType) }} <span class="currency">{{getCoinCurrencyByNftType(galleryProductInfo.nftType)}}</span>
                    </template>
                  </template>
                  <template
                    v-else-if="galleryProductInfo.status === 'SOLDOUT' &&
                                activeLanguage !== undefined &&
                                activeLanguage === 'en' &&
                                galleryProductInfo.soldCoinPrice > 0 &&
                                galleryProductInfo.soldCoinCurrency !== undefined"
                  >
                    {{ Number(galleryProductInfo.soldCoinPrice) | currencyCoin(galleryProductInfo.soldCoinCurrency) }} {{ getCoinCurrencyByCurrency(galleryProductInfo.soldCoinCurrency) }}
                  </template>
                  <template v-else-if="galleryProductInfo.status === 'PRIVATESOLD'"></template>
                  <template v-else>
                    <template v-if="galleryProductInfo.nftType === 'ORDINALS'">
                      {{ getSatoshiToKRW(galleryProductInfo.productCoinPrice)}} <span class="currency">KRW</span>
                    </template>
                    <template v-else>
                      {{ galleryProductInfo.productPrice | currency }} <span class="currency">KRW</span>
                    </template>
                  </template>
                </template>
              </div>
            </div>
          </div>
          <div>
            <div>
              <b-button
                pill
                class="payment-btn"
                :disabled="galleryProductInfo.status !== 'FORSALE'"
                :class="{'cursor-default': galleryProductInfo.status !== 'FORSALE'}"
                @click="onClickPayment"
              >
                <template v-if="galleryProductInfo.status === 'PREPARING'">{{ $t("product.statusForSaleDetail") }}</template>
                <template v-else-if="galleryProductInfo.status === 'EXHIBIT'">{{ $t("product.statusExhibit") }}</template>
                <template v-else-if="galleryProductInfo.status === 'FORSALE'">{{ $t("product.statusForSaleDetail") }}</template>
                <template v-else-if="galleryProductInfo.status === 'RESERVE'">{{ $t("product.statusReserve") }}</template>
                <template v-else-if="galleryProductInfo.status === 'SOLDOUT' && galleryProductInfo.buyerNickname !== undefined">{{ "Owned by @" + galleryProductInfo.buyerNickname }}</template>
                <template v-else-if="galleryProductInfo.status === 'PRIVATESOLD' && galleryProductInfo.buyerNickname !== undefined">{{ "Owned by @" + galleryProductInfo.buyerNickname }}</template>
              </b-button>
            </div>
            <div class="artwork-proof-section">
              <hr/>
              <div class="artwork-proof-title">
                {{ $t("product.authentication") }}
                <img
                  id="proof-info"
                  src="/img/threespace/icon/icon-info.png"
                />
                <b-tooltip target="proof-info">
                  <template v-if="galleryProductInfo.nftType === 'ETHEREUM'">
                    {{ $t("product.proofMsgEthereum") }}
                  </template>
                  <template v-else-if="galleryProductInfo.nftType === 'POLYGON'">
                    {{ $t("product.proofMsgPolygon") }}
                  </template>
                  <template v-else-if="galleryProductInfo.nftType === 'KLAYTN' || galleryProductInfo.nftType === 'KLIP'">
                    {{ $t("product.proofMsgKlayten") }}
                  </template>
                </b-tooltip>
              </div>
              <div class="artwork-proof-detail">
                <template v-if="galleryProductInfo.nftType === 'ORDINALS'">
                  <div class="artwork-proof-item">
                    <span class="type">Inscription Num</span>
                    <span>{{galleryProductInfo.inscriptionNumber}}</span>
                  </div>
                  <div class="artwork-proof-item">
                    <span class="type">Inscription ID</span>
                    <span>{{galleryProductInfo.inscriptionId | inscriptionId}}</span>
                  </div>
                </template>
                <template v-else>
                  <div class="artwork-proof-item">
                    <span class="type">{{ $t("product.id") }}</span>
                    <span>{{ galleryProductInfo.tokenId || '(정보없음)' }}</span>
                  </div>
                  <div class="artwork-proof-item">
                    <span class="type">{{ $t("product.contract") }}</span>
                    <span>{{ galleryProductInfo.contractAddr || '(정보없음)' }}</span>
                  </div>
                </template>
              </div>
              <hr/>
              <div class="explorer-wrap">
                <template v-if="galleryProductInfo.nftType === 'ORDINALS'">
                  <a @click="onClickOrdinalsExplore" class="explorer">
                    <b-img src="/img/threespace/company/ordinals.png" />
                    <div class="pt-1">{{ $t("product.ordinals") }}</div>
                  </a>
                  <a @click="onClickMagicEden" class="explorer">
                    <b-img src="/img/threespace/company/magic-eden.png" />
                    <div class="pt-1">{{ $t("product.magicEden") }}</div>
                  </a>
                </template>
                <template v-if="galleryProductInfo.nftType === 'ETHEREUM'">
                  <a @click="onClickBlockchainExplore" class="explorer">
                    <b-img src="/img/threespace/company/ethereum-eth-logo.svg" />
                    <div class="pt-1">{{ $t("product.etherscan") }}</div>
                  </a>
                </template>
                <template v-if="galleryProductInfo.nftType === 'POLYGON'">
                  <a @click="onClickBlockchainExplore" class="explorer">
                    <b-img src="/img/threespace/company/polygon-matic-logo.png" />
                    <div class="pt-1">{{ $t("product.polygonscan") }}</div>
                  </a>
                </template>
                <template v-if="galleryProductInfo.nftType === 'KLAYTN' || galleryProductInfo.nftType === 'KLIP'">
                  <a @click="onClickBlockchainExplore" class="explorer">
                    <b-img src="/img/threespace/company/kaia-black.png" />
                    <div class="pt-1">{{ $t("product.klaytnScope") }}</div>
                  </a>
                </template>
                <template v-if="galleryProductInfo.opensea !== undefined && galleryProductInfo.opensea !== null">
                  <a @click="onClickOpensea" class="explorer">
                    <b-img src="/img/threespace/company/opensea_logo_blue.svg" />
                    <div class="pt-1">{{ $t("product.opensea") }}</div>
                  </a>
                </template>
              </div>
              <hr/>
              <div class="artwork-proof-title">
                히스토리
              </div>
              <div class="transaction-wrap">
                <template v-for="(item, i) in galleryProductInfo.receipts">
                  <div class="transaction-item" :key="i">
                    <div>{{ replaceHistoryName(item.state) }}</div>
                    <div class="name" @click="onClickUserNickname(item)" :class="{nicknameHover: item.member}">
                      @
                      {{
                        item.state === 'MINT' ?
                          artistInfo.artistNickName :
                          item.member ?
                            item.member.nickname :
                            item.toAddr.slice(0,3) + "..." + item.toAddr.slice(-3)
                      }}
                    </div>
                    <div>
                      {{ item.createdAt | moment("YYYY.MM.DD") }}
                      <template v-if="item.klaytnScopeUrl != ''">
                        <CLink :href="item.klaytnScopeUrl" target="_blank">
                          <CIcon name="cil-external-link" />
                        </CLink>
                      </template>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row inscription-wrap" v-if="ordinalsInfo !== null">
        <div class="col-12 px-0">
          <p>{{ $t("product.detail") }}</p>
          <hr />
          <div class="row">
            <div v-for="(value, key) in ordinalsInfo" :key="key" class="col-12 col-xl-6 col-lg-12 col-md-12 col-sm-12 inscription-info">
              <span class="key">{{key}}:</span>
              <span class="value">{{value}}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="artist-artwork-section">
        <div class="artist_art_box">
          <div class="artist-artwork-title">
            <div>
              다른작품 둘러보기
            </div>
            <img
              @click="onClickArtist"
              v-if="galleryArtistRecentArts.length !== 0"
              src="/img/threespace/icon/chevron-right-32.png"
            />
          </div>
        </div>
        <ArtworkArtList
          ref="artworkArtList"
          :idxArtist="artistInfo.idxArtist"
        />
      </div>
    </div>

    <!-- 결제, 로그인 메시지 팝업 -->
    <b-modal
      id="paymentLoginModal"
      ref="paymentLoginModal"
      hide-header
      hide-footer
      centered
      body-class="text-center my-4"
    >
      <b-img src="/img/threespace/logo/logo_100x100.png" width="70"></b-img>
      <p class="mt-3 text-dark">
        {{ $t("product.requiredLogin") }}
      </p>
      <div class="w-100 text-center mt-5">
        <b-button
          variant="primary"
          size="lg"
          squared
          class="text-white px-5 py-2"
          @click="goLogin()"
        >
          {{ $t("button.confirm") }}
        </b-button>
      </div>
    </b-modal>

    <!-- 결제, 필수정보 메시지 팝업
    <b-modal
      id="paymentRequiredModal"
      ref="paymentRequiredModal"
      hide-header
      hide-footer
      centered
      body-class="text-center my-4"
    >
      <b-img src="/img/threespace/logo/logo_100x100.png" width="70"></b-img>
      <p class="mt-3 text-dark">
        결제를 위해 <span class="text-danger">연락처</span>,
        <span class="text-danger">이메일</span>,
        <template v-if="galleryProductInfo.nftType === 'ETHEREUM'">
          <span class="text-danger">이더리움 지갑주소</span>
        </template>
        <template v-else-if="galleryProductInfo.nftType === 'KLAYTN' || galleryProductInfo.nftType === 'KLIP'">
          <span class="text-danger">클레이튼 지갑주소</span>
        </template>는 필수정보입니다.
      </p>
      <p class="text-dark">
        마이페이지에서 설정을 완료해주세요.
      </p>
      <div class="w-100 text-center mt-5">
        <b-button
          variant="primary"
          squared
          class="text-white px-5 py-2"
          @click="goMyPage()"
        >
          마이페이지로 이동
        </b-button>
      </div>
    </b-modal>
    -->

    <!-- 지갑 네트워크 메시지 팝업 -->
    <b-modal
      id="paymentWalletModal"
      ref="paymentWalletModal"
      hide-header
      hide-footer
      centered
      body-class="text-center my-4"
    >
      <b-img src="/img/threespace/logo/logo_100x100.png" width="70"></b-img>
      <p class="mt-3 text-dark" v-html="walletMessage"></p>
      <div class="w-100 text-center mt-5">
        <b-button
          variant="primary"
          size="lg"
          squared
          class="text-white px-5 py-2"
          @click="$refs.paymentWalletModal.hide()"
        >
          {{ $t("button.confirm") }}
        </b-button>
      </div>
    </b-modal>

    <TermsPopup :terms-info="termsInfo" ref="termsModal" />
    <WalletPopup ref="walletPopupModal" @wallet-info="walletLogin"/>
  </div>
</template>

<script>
import {createNamespacedHelpers, mapActions, mapState} from "vuex";
const galleryHelper = createNamespacedHelpers("gallery");
const authHelper = createNamespacedHelpers("auth");
const codeHelper = createNamespacedHelpers("code");
const coinHelper = createNamespacedHelpers("coin");
import TermsPopup from "@/views/threespace/TermsPopup";
import WalletPopup from "@/views/threespace/WalletPopup";
import ArtworkArtList from './artwork/ArtworkArtList.vue';
import CoinUtils from "../../mixins/CoinUtils";

export default {
  name: "Artwork",
  components: {
    TermsPopup,
    WalletPopup,
    ArtworkArtList
  },
  props: {
    idxProduct: {
      type: String,
      default: "0"
    },
    idxCompany: {
      type: String,
      default: "0"
    }
  },
  mixins: [CoinUtils],
  // metaInfo() {
  //   return {
  //     title: (this.activeLanguage === "ko" ? this.galleryProductInfo.productName : this.galleryProductInfo.productNameEn) + " | 3space Art",
  //     meta: [
  //       { vmid: "title", name: "title", content: (this.activeLanguage === "ko" ? this.galleryProductInfo.productName : this.galleryProductInfo.productNameEn) + " | 3space Art" },
  //       { vmid: "description", name: "description", content: this.activeLanguage === "ko" ? this.galleryProductInfo.productDescription : this.galleryProductInfo.productDescriptionEn },
  //       { vmid: "og:title", property: "og:title", content: (this.activeLanguage === "ko" ? this.galleryProductInfo.productName : this.galleryProductInfo.productNameEn) + " | 3space Art" },
  //       { vmid: "og:description", property: "og:description", content: this.activeLanguage === "ko" ? this.galleryProductInfo.productDescription : this.galleryProductInfo.productDescriptionEn },
  //       { vmid: "og:url", property: "og:url", content: window.location.origin + this.$route.path }
  //     ]
  //   }
  // },
  data() {
    return {
      showMore: true,
      isOverText: false,
      artistInfo: {},
      videoOptions: {
        muted: true, //TODO
        fullscreen: false
      },
      isProductItemLoaded: false,
      showProductControls: false,
      historyOptions: [
        { code: "product.historyMINT", state: "MINT" },
        { code: "product.historyPURCHASE", state: "PURCHASE" },
        { code: "product.historyTRANSFER", state: "TRANSFER" }
      ],
      message: "",
      // Artist works
      isBusy: false,
      externalParams: {
        idxArtist: null
      },
      imageProps: {
        blank: true,
        blankColor: "#F2F2F2"
      },
      walletMessage: "",
      ordinalsInfo: null
    };
  },
  computed: {
    ...codeHelper.mapState(["termsInfo"]),
    ...authHelper.mapState(["me"]),
    ...galleryHelper.mapState(["galleryProductInfo", "galleryProductItemsContent", "galleryArtistRecentArts"]),
    isMobile() {
      return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    },
    activeLanguage() {
      return this.getActiveLanguage();
    },
    getProductNetwork() {
      const nftType = this.galleryProductInfo.nftType;
      let network = null;

      if (nftType === "KLAYTN" || nftType === "KLIP") {
        network = "Klaytn";
      } else if (nftType === "ETHEREUM") {
        network = "Ethereum";
      } else if (nftType === "POLYGON") {
        network = "Polygon";
      } else if (nftType === "ORDINALS") {
        network = "Bitcoin";
      }
      return network;
    }
  },
  mounted() {
    this.getTickers();
    this.requestGalleryProductItem();

    this.$refs.productMediaWrap.addEventListener(
      "fullscreenchange",
      this.exitHandler
    );
    this.$refs.productMediaWrap.addEventListener(
      "webkitfullscreenchange",
      this.exitHandler
    );
    this.$refs.productMediaWrap.addEventListener(
      "mozfullscreenchange",
      this.exitHandler
    );
    this.$refs.productMediaWrap.addEventListener(
      "MSFullscreenChange",
      this.exitHandler
    );
  },
  methods: {
    ...galleryHelper.mapActions(["getDetailProduct"]),
    ...authHelper.mapActions(["verifyTokenCallback"]),
    ...codeHelper.mapActions(["getTerms"]),
    ...mapActions("wallet", ["walletLogin", "getInscriptionInfo"]),
    ...coinHelper.mapActions(["getTickers"]),
    onClickUserNickname(item) {
      if (item.state === 'MINT') {
        let idxEncode = window.btoa(this.galleryProductInfo.idxMember);
        this.$router.push("/collections/" + idxEncode);
      } else {
        if (item.member) {
          let idxEncode = window.btoa(item.member.idx);
          this.$router.push("/collections/" + idxEncode);
        }
      }
    },
    getActiveLanguage() {
      return localStorage.getItem("language") || "en";
    },
    exitHandler() {
      if (
        !document.fullscreenElement &&
        !document.webkitIsFullScreen &&
        !document.mozFullScreen &&
        !document.msFullscreenElement
      ) {
        // fire your event
        this.videoOptions.fullscreen = false;
      }
    },
    getProductItemSize() {
      this.$nextTick(() => {
        if (this.isProductFileTypeImage(this.galleryProductInfo.productFileType)) {
          this.showProductControls = true;
        } else {
          this.$refs.artworkVideo.addEventListener(
            "loadeddata",
            () => {
              this.showProductControls = true;
            },
            false
          );
        }
      });
      this.isProductItemLoaded = true;
    },
    toggleFullScreen() {
      // IOS >=10 in Safari and Chrome 은 지원 안됨
      const elem = document.getElementById("productMediaWrap");

      if (!document.fullscreenElement) {
        elem.requestFullscreen().then(() => {
          this.videoOptions.fullscreen = true;
        });
      } else {
        document.exitFullscreen().then(() => {
          this.videoOptions.fullscreen = false;
        });
      }
    },
    toggleClickMuted() {
      this.videoOptions.muted = !this.videoOptions.muted;
    },
    productIntroduceHeightCut() {
      this.$nextTick(() => {
        if (this.$refs.productIntroduceText.clientHeight > 200) {
          this.isOverText = true;
        } else {
          this.isOverText = false;
        }
      }, 50000);
    },
    async requestInscriptionInfo() {
      if(this.galleryProductInfo.nftType === 'ORDINALS'){
        this.getInscriptionInfo(this.galleryProductInfo.inscriptionId)
        .then(async(data) => {
          if(data.error || !data){
            return;
          }
          const needAbbreviate = ['id', 'address', 'genesis_address', 'genesis_block_hash', 'genesis_tx_id', 'location', 'output', 'tx_id']
          for(let key in data){
            if(needAbbreviate.includes(key)){
              data[key] = data[key].substr(0,5) + '...' + data[key].substr(-3);
            }
            if(data[key] === null){
              delete data[key]
            }
          }
          this.ordinalsInfo = data;
        })
      }
    },
    requestGalleryProductItem() {
      if (this.idxProduct && this.idxProduct !== "0") {
        // 법인용 링크에서 접근한 경우
        if (this.idxCompany && this.idxCompany !== "0") {
          this.$log.log(this.idxCompany);
          localStorage.setItem("referralCorpSeller_" + this.idxProduct, this.idxProduct + "_" + this.idxCompany);
          localStorage.setItem("referral3spaceJoin", this.idxCompany);
        }

        this.getDetailProduct(this.idxProduct).then(data => {
          if (
            Object.keys(data).length !== 0 &&
            JSON.stringify(data) !== JSON.stringify({})
          ) {
            // 음향
            if (!this.isMobile && this.galleryProductInfo.productSound) {
              this.videoOptions.muted = false;
            }

            // 이더스캔/클레이튼 스콥에서 보기 URL
            let blockchainExplore = "#";
            if (data.contractAddr != undefined && data.contractAddr != null && data.contractAddr != ""
              && data.tokenId != undefined && data.tokenId != null && data.tokenId != ""
              && data.nftType != undefined && data.nftType != null && data.nftType != ""
            ) {
              if (data.nftType === "KLAYTN" || data.nftType === "KLIP") {
                blockchainExplore = process.env.VUE_APP_KLAYTNSCOPE_URL + "/nft/" + data.contractAddr + "/" + data.tokenId + "?tabId=nftTransfer";
              } else if (data.nftType === "ETHEREUM") {
                blockchainExplore = process.env.VUE_APP_ETHERSCAN_URL + "/token/" + data.contractAddr + "?a=" + data.tokenId;
              } else if (data.nftType === "POLYGON") {
                blockchainExplore = process.env.VUE_APP_POLYGONSCAN_URL + "/token/" + data.contractAddr + "?a=" + data.tokenId;
              }
            }
            this.galleryProductInfo.blockchainExplore = blockchainExplore;

            // 오픈씨 URL
            if (data.contractAddr !== undefined && data.contractAddr != null && data.tokenId !== undefined && data.tokenId !== null) {
              if (data.nftType === "KLAYTN" || data.nftType === "KLIP") {
                this.galleryProductInfo.opensea = process.env.VUE_APP_OPENSEA_URL + "/assets/klaytn/" + data.contractAddr + "/" + data.tokenId;
              } else if (data.nftType === "ETHEREUM") {
                this.galleryProductInfo.opensea = process.env.VUE_APP_OPENSEA_URL + "/assets/" + data.contractAddr + "/" + data.tokenId;
              } else if (data.nftType === "POLYGON") {
                this.galleryProductInfo.opensea = process.env.VUE_APP_OPENSEA_URL + `/assets/${process.env.VUE_APP_OPENSEA_POLYGON_NETWORK}/` + data.contractAddr + "/" + data.tokenId;
              }
            }

            // 작가 정보
            this.artistInfo = {
              idxArtist: data.idxArtist,
              idxMember: data.idxMember,
              artistName: data.artistName,
              artistNickName: data.artistNickName
            };
            this.getProductItemSize();
            this.productIntroduceHeightCut();
            this.$refs["artworkArtList"].requestArtistRecentArts(data.idxArtist);
            this.requestInscriptionInfo();
          }
        });
      }
    },
    isProductFileTypeImage(_productFileType) {
      if (
        _productFileType !== undefined &&
        _productFileType.startsWith("image")
      ) {
        return true;
      } else {
        return false;
      }
    },
    onClickOrdinalsExplore() {
      if(this.galleryProductInfo.inscriptionId !== undefined && this.galleryProductInfo.inscriptionId !== null) {
        const ordinalsUrl = process.env.NODE_ENV === 'production' ? 'https://ordinals.com/inscription/' : 'https://testnet.ordinals.com/inscription/'
          window.open(`${ordinalsUrl}${this.galleryProductInfo.inscriptionId}`)
      }
    },
    onClickMagicEden() {
      if(this.galleryProductInfo.inscriptionId !== undefined && this.galleryProductInfo.inscriptionId !== null) {
        const magicEdenUrl = 'https://magiceden.io/ordinals/item-details/'
        window.open(`${magicEdenUrl}${this.galleryProductInfo.inscriptionId}`)
      }
    },
    onClickBlockchainExplore() {
      if (this.galleryProductInfo.blockchainExplore !== undefined && this.galleryProductInfo.blockchainExplore !== null) {
        window.open(this.galleryProductInfo.blockchainExplore, "_blank");
      }
    },
    onClickOpensea() {
      if (this.galleryProductInfo.opensea !== undefined && this.galleryProductInfo.opensea !== null) {
        window.open(this.galleryProductInfo.opensea, "_blank");
      }
    },
    onClickArtist() {
      // this.$router.push("/artist/" + this.artistInfo.idxArtist);
      let idxEncode = window.btoa(this.artistInfo.idxMember);
      this.$router.push("/collections/" + idxEncode);
    },
    onClickPayment() {
      // if(this.galleryProductInfo.nftType === "ORDINALS"){
      //   alert("COMING SOON")
      // }
      // else {
        this.verifyTokenCallback()
          .then(() => {
            this.walletMessage = "";
            if (
              this.me === undefined ||
              this.me === null ||
              this.me.idx === undefined ||
              this.me.idx === null ||
              this.me.idx === ""
            ) {
              // 로그인
              this.$refs["walletPopupModal"].login(this.getProductNetwork);
            } else {
              let isOk = true;
              if (this.me.snsType === "WALLET") {
                // 작품과 연결된 지갑 네트워크가 다른 경우 지갑 변경 모달 띄우기
                if (this.getProductNetwork === "Klaytn" && (this.me.klaytnAddr === undefined || this.me.klaytnAddr === null || this.me.klaytnAddr === "")) {
                  isOk = false;
                }
                if (this.getProductNetwork === "Ethereum" && (this.me.ethereumAddr === undefined || this.me.ethereumAddr === null || this.me.ethereumAddr === "")) {
                  isOk = false;
                }
                if (this.getProductNetwork === "Polygon" && (this.me.polygonAddr === undefined || this.me.polygonAddr === null || this.me.polygonAddr === "")) {
                  isOk = false;
                }
                if (this.getProductNetwork === "Bitcoin" && (this.me.btcAddr === undefined || this.me.btcAddr === null || this.me.btcAddr === "")) {
                  isOk = false;
                }
                console.log(this.getProductNetwork, this.me.btcAddr)
              }
              if (!isOk) {
                this.walletMessage = this.$t("payment.requiredWalletMainnet").replaceAll("{network}", this.getProductNetwork);
                this.$refs["paymentWalletModal"].show();
              } else {
                // 결제하기
                this.$router.push("/payment/" + this.galleryProductInfo.idx);
              }
            }
          })
          .catch(error => {
            this.$log.error(error);
            // 로그인
            this.$refs["walletPopupModal"].login(this.getProductNetwork);
          });
      // }
    },
    showTermsModal(_code) {
      //this.$log.info("Product.showTermsModal() code=", _code);
      const param = { code: _code };
      this.getTerms(param).then(() => {
        this.$refs["termsModal"].show(_code);
      });
    },
    getReplaceNewLineDesc() {
      let _desc = "";
      let _moreDesc = "";
      if (this.activeLanguage !== undefined && this.activeLanguage === "ko") {
        _desc = this.galleryProductInfo.productDescription;
        if (_desc === undefined || _desc === null || _desc === "") {
          _desc = this.galleryProductInfo.productDescriptionEn;
        }
        _moreDesc = this.galleryProductInfo.productMoreDescription;
        if (_moreDesc === undefined || _moreDesc === null || _moreDesc === "") {
          _moreDesc = this.galleryProductInfo.productMoreDescriptionEn;
        }
      } else {
        _desc = this.galleryProductInfo.productDescriptionEn;
        if (_desc === undefined || _desc === null || _desc === "") {
          _desc = this.galleryProductInfo.productDescription;
        }
        _moreDesc = this.galleryProductInfo.productMoreDescriptionEn;
        if (_moreDesc === undefined || _moreDesc === null || _moreDesc === "") {
          _moreDesc = this.galleryProductInfo.productMoreDescription;
        }
      }

      if (_desc !== undefined && _desc !== null && _desc !== "") {
        _desc = _desc.replace(/(?:\r\n|\r|\n)/g, "<br />");
      }
      if (_moreDesc !== undefined && _moreDesc !== null && _moreDesc !== "") {
        _moreDesc = _moreDesc.replace(/(?:\r\n|\r|\n)/g, "<br />");
        _desc = _desc + "<br /><br />" + _moreDesc;
      }
      return _desc;
    },
    replaceDisplayPosterUrl(item) {
      if (
        item.displayPoster !== undefined &&
        item.displayPoster !== null &&
        item.displayPoster !== "" &&
        item.displayPoster === "THREESPACE"
      ) {
        return item.displayPosterUrlThreespace; // 3space S3 URL
      } else {
        if (item.displayPosterUrl !== undefined && item.displayPosterUrl.startsWith("https://ipfs.io/")) {
          return item.displayPosterUrl.replace("https://ipfs.io/", "https://ipfs.3space.art/");
        }
        return item.displayPosterUrl;
      }
    },
    replaceDisplayDetailUrl(item) {
      if (
        item.displayDetailFile !== undefined &&
        item.displayDetailFile !== null &&
        item.displayDetailFile !== "" &&
        item.displayDetailFile === "THREESPACE"
      ) {
        return item.displayDetailUrlThreespace; // 3space S3 URL
      } else {
        if (
          item.displayDetailUrl !== undefined &&
          item.displayDetailUrl.startsWith("https://ipfs.io/")
        ) {
          return item.displayDetailUrl.replace(
            "https://ipfs.io/",
            "https://ipfs.3space.art/"
          );
        }
        return item.displayDetailUrl;
      }
    },
    replaceHistoryName(state) {
      let text = "";
      if (state !== undefined && state !== null && state !== "") {
        let item = this.historyOptions.find(o => o.state === state);
        text = this.$t(item.code);
      }
      return text;
    },
    goLogin() {
      this.$router.push({ name: "Login" });
    },
    goMyPage() {
      if (this.me.idx > 0) {
        //this.$router.push("/mypage/" + this.me.idx).catch(() => {});
        this.$router.push({
          name: "MyPage",
          params: { idxMember: this.me.idx, activeTab: 0 }
        });
      }
    },
  },
  filters: {
    currencyCoin: function(value,currency){
      if(currency === "ETH"){
        return parseFloat(Math.floor(value * 1000) / 1000)
          .toFixed(3)
          .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
      }

      return parseInt(value)
        .toFixed(0)
        .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    },
    currency: value => {
      if (!value) return "";
      return value.toFixed().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    },
    inscriptionId: value => {
      if(!value) return "정보없음";
      return `${value.substr(0,5)}...${value.substr(-5)}`
    }
  }
};
</script>
